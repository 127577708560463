<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S') && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Professor</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Professor
                  <span>Virtual</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <section class="fd-mode-opcoes">                    
                    <!-- Dashboard -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a    
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"    
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/dashboard'"
                          class="col-sm-12 col-md-12 col-lg-8"                  
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/dashboard')"                          
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/matriz-icon.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Dashboard docente</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- cursos -->
                    <div                      
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxCursos"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxCursos"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a
                          :href="
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/professor/meus-cursos'
                          "
                          class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' +
                            $route.params.id_plataforma +
                            '/professor/meus-cursos')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                          >
                            <img
                              v-if="
                                ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_meus_cursos)
                              "
                              :src="
                              ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_meus_cursos)
                              "
                              class="img-fluid"
                              style="max-width: 112px"
                            >
                            <img
                              v-else
                              :src="require('@/assets/images/app/cursos-icon.png')"
                              class="img-fluid"
                            >
                            <h3
                              v-if="
                                $store.state.fastPersonalizacao.meus_cursos_nome
                              "
                              class="mt-0 mt-sm-1 menu_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.meus_cursos_nome }}
                            </h3>
                            <h3
                              v-else
                              class="mt-0 mt-sm-1 menu_font_color"
                            >
                              Meus Cursos
                            </h3>
                          </div>
                        </a>
                      </div>
                      <div
                        class="fd-mode-opcoes-item-dicas alert alert-info"
                        style="display: none"
                        role="alert"
                      >
                        <i class="fas fa-info-circle" /> Busque cursos disponíveis
                        do Fastead e/ou crie seus próprios cursos.
                      </div>
                    </div>
                    <div
                      v-if="$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives"
                      class="fd-mode-opcoes-item"
                    >
                      <div class="row">
                        <a
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/lives'"
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{ backgroundColor: $store.state.fastPersonalizacao.menu_background_color ? $store.state.fastPersonalizacao.menu_background_color : '#fff' }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/lives')"
                        >
                          <img
                            v-if="$store.state.fastPersonalizacao.menu_icone_minhas_lives"
                            :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_minhas_lives)"
                            class="img-fluid"
                            style="max-width: 112px"
                          >
                          <img
                            v-else
                            :src="require('@/assets/images/app/usuarios-icon.png')"
                            class="img-fluid"
                          >
                          <h3
                            v-if="$store.state.fastPersonalizacao.minhas_lives_nome"
                            class="mt-0 mt-sm-1 menu_font_color"
                          >{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</h3>
                          <h3
                            v-else
                            class="mt-0 mt-sm-1 menu_font_color"
                          >Minhas lives</h3>
                        </a>
                      </div>
                    </div>
                    <!-- Agenda -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a       
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria" 
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/agenda'"
                          class="col-sm-12 col-md-12 col-lg-8"                
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/agenda')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/schedule.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Agenda</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Turmas -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a        
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/turmas'"
                          class="col-sm-12 col-md-12 col-lg-8"                
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/turmas')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/videoconference.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Turmas</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Perguntas -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a        
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/perguntas'"
                          class="col-sm-12 col-md-12 col-lg-8"            
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/perguntas')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/question.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Perguntas</h3>
                          </div>
                        </a>
                      </div>
                    </div>

                    <!-- Aulas particular -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a        
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/aulas-particulares'"
                          class="col-sm-12 col-md-12 col-lg-8"                
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/aulas-particulares')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/teaching.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Aula particular</h3>
                          </div>
                        </a>
                      </div>
                    </div>

                    <!-- Revisão de redação -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a        
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                          :href="'/plataforma/' + $route.params.id_plataforma + '/professor/revisoes-redacao'"
                          class="col-sm-12 col-md-12 col-lg-8"                 
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor/revisoes-redacao')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/responsive.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Revisão de redação</h3>
                          </div>
                        </a>
                      </div>
                    </div>

                    <!-- Financeiro -->
                    <div 
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a        
                          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                          href="#"
                          style="cursor: not-allowed;"
                          class="col-sm-12 col-md-12 col-lg-8"                 
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="exibeToasty('Acesso não autorizado', 'error')"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          >
                            <img
                              :src="require('@/assets/images/app/financeiro-icon.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Financeiro</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma 
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoAdminTesouraria",
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
