var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.$store.state.fastPermissoes.administrador == 'S' || _vm.$store.state.fastPermissoes.professor == 'S') && _vm.$store.state.fastPermissoes.ativo == 'S')?_c('div',[_c('div',{staticClass:"fd-app-wrap"},[_c('div',{staticClass:"container"},[_c('nav',[_c('ul',{staticClass:"fd-app-breadcrumb"},[_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"/minhas-plataformas"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/minhas-plataformas')}}},[_vm._v("Minhas Plataformas")])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma)}}},[_vm._v("Plataforma "+_vm._s(_vm.$store.state.fastPlataforma.nome_plataforma))])]),_vm._m(0)])])]),_c('div',{staticClass:"fd-mode-adm-content mt-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0",attrs:{"id":"body_conteudo_modo_admin"}},[_c('section',{staticClass:"fd-app-welcome text-left"},[_vm._m(1),_c('img',{attrs:{"src":require('@/assets/images/form-effect-01.png')}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('section',{staticClass:"fd-mode-opcoes"},[_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/dashboard'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/dashboard')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/matriz-icon.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Dashboard docente")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxCursos","role":"button","aria-expanded":"false","aria-controls":"collapseBoxCursos","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' +
                            _vm.$route.params.id_plataforma +
                            '/professor/meus-cursos'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' +
                          _vm.$route.params.id_plataforma +
                          '/professor/meus-cursos')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"},[(
                              _vm.ajustaLinkApiAntiga(_vm.$store.state.fastPersonalizacao.menu_icone_meus_cursos)
                            )?_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"112px"},attrs:{"src":_vm.ajustaLinkApiAntiga(_vm.$store.state.fastPersonalizacao.menu_icone_meus_cursos)}}):_c('img',{staticClass:"img-fluid",attrs:{"src":require('@/assets/images/app/cursos-icon.png')}}),(
                              _vm.$store.state.fastPersonalizacao.meus_cursos_nome
                            )?_c('h3',{staticClass:"mt-0 mt-sm-1 menu_font_color"},[_vm._v(" "+_vm._s(_vm.$store.state.fastPersonalizacao.meus_cursos_nome)+" ")]):_c('h3',{staticClass:"mt-0 mt-sm-1 menu_font_color"},[_vm._v(" Meus Cursos ")])])])]),_vm._m(2)]),(_vm.$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives)?_c('div',{staticClass:"fd-mode-opcoes-item"},[_c('div',{staticClass:"row"},[_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded",style:({ backgroundColor: _vm.$store.state.fastPersonalizacao.menu_background_color ? _vm.$store.state.fastPersonalizacao.menu_background_color : '#fff' }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/lives'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/lives')}}},[(_vm.$store.state.fastPersonalizacao.menu_icone_minhas_lives)?_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"112px"},attrs:{"src":_vm.ajustaLinkApiAntiga(_vm.$store.state.fastPersonalizacao.menu_icone_minhas_lives)}}):_c('img',{staticClass:"img-fluid",attrs:{"src":require('@/assets/images/app/usuarios-icon.png')}}),(_vm.$store.state.fastPersonalizacao.minhas_lives_nome)?_c('h3',{staticClass:"mt-0 mt-sm-1 menu_font_color"},[_vm._v(_vm._s(_vm.$store.state.fastPersonalizacao.minhas_lives_nome))]):_c('h3',{staticClass:"mt-0 mt-sm-1 menu_font_color"},[_vm._v("Minhas lives")])])])]):_vm._e(),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/agenda'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/agenda')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/schedule.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Agenda")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/turmas'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/turmas')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/videoconference.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Turmas")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/perguntas'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/perguntas')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/question.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Perguntas")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/aulas-particulares'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/aulas-particulares')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/teaching.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Aula particular")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor/revisoes-redacao'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor/revisoes-redacao')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/responsive.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Revisão de redação")])])]):_vm._e()])]),_c('div',{staticClass:"fd-mode-opcoes-item position-relative",attrs:{"data-toggle":"collapse","href":"#collapseBoxPersonalizar","role":"button","aria-expanded":"false","aria-controls":"collapseBoxPersonalizar","dont-toogle-lg":""}},[_c('div',{staticClass:"row align-items-center"},[(_vm.$store.state.fastPlataformaModulos.modulo_secretaria)?_c('a',{staticClass:"col-sm-12 col-md-12 col-lg-8",staticStyle:{"cursor":"not-allowed"},style:({
                          backgroundColor: _vm.$store.state.fastPersonalizacao
                            .menu_background_color
                            ? _vm.$store.state.fastPersonalizacao
                              .menu_background_color
                            : '#fff',
                        }),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.exibeToasty('Acesso não autorizado', 'error')}}},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"},[_c('img',{staticClass:"img-fluid mr-2",staticStyle:{"max-width":"115px"},attrs:{"src":require('@/assets/images/app/financeiro-icon.png')}}),_c('h3',{staticClass:"menu_font_color"},[_vm._v("Financeiro")])])]):_vm._e()])])])])]),_c('div',{staticClass:"mt-5 mb-5"},[_c('a',{staticClass:"btn btn-red-hollow",attrs:{"id":"btn_voltar_modo_admin","href":'/plataforma/' + _vm.$route.params.id_plataforma},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma)}}},[_c('b-icon-arrow-return-left'),_vm._v(" Voltar ")],1)])])])])])]),_c('notifications',{attrs:{"group":"foo","close-on-click":false,"position":"bottom right"}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fd-app-breadcrumb-item active"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"}},[_vm._v("Professor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"aluno_font_color"},[_vm._v(" Professor "),_c('span',[_vm._v("Virtual")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fd-mode-opcoes-item-dicas alert alert-info",staticStyle:{"display":"none"},attrs:{"role":"alert"}},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Busque cursos disponíveis do Fastead e/ou crie seus próprios cursos. ")])
}]

export { render, staticRenderFns }